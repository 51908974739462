<template>
  <metainfo></metainfo>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain isTransparent="is-transparent"></HeaderMain>
      <!-- hero -->
<!--            <HeroTwo></HeroTwo>-->
    </header>

    <!--    <TopGlobalTip></TopGlobalTip>-->
    <div id="promotions-popup"
         @click="handleClick" v-if="promotionsShow"
         class="modal fade show" style="display: block;" tabindex="-1" aria-modal="true" role="dialog">
      <div v-if="alert.type===1" class="modal-dialog modal-dialog-centered modal-xl">
        <img :class="[sharkFlag ?'my-shark' :'']"
             :src="alert.presignedUrl"
             alt=""
             ref="image"/>
      </div>
    </div>

    <div class="main-content">
      <HeroEight></HeroEight>
      <HowItWorkTwo></HowItWorkTwo>
      <section class="section-space trending-section">
        <div class="container">
          <!-- section heading -->
          <div class="section-head text-center">
            <h2 class="isMargin">Trending Contracts</h2>
            <p>Multiple meticulously designed cloud mining investment schemes ensure your stable and reliable investment returns.</p>
          </div><!-- end section-head -->
          <!-- product -->
          <ProductsContainerSix></ProductsContainerSix>
          <div class="text-center mt-4 mt-md-5">
            <router-link class="btn-link btn-link-s1" to="/contracts"> View all contracts</router-link>
          </div>
        </div><!-- .container -->
      </section><!-- trending-section -->

      <!-- Featured  -->
      <!--      <Featured></Featured>-->


      <!--    AboutUs-->
      <AboutUs classname="col-lg-3" :title="SectionData.howItWorkData.title"
               :subtitle="SectionData.howItWorkData.content"
               gutterBottom="mb-3"></AboutUs>

      <!-- HowItWork  -->
      <!--              <HowItWork classname="col-lg-3" :title="SectionData.howItWorkData.title" :subtitle="SectionData.howItWorkData.content" gutterBottom="mb-3"></HowItWork>-->
      <!--      <section class="section-space" style="background: #F7F9FB;">-->

      <!--        <div class="container">-->
      <!--          &lt;!&ndash;        <SectionHeading classname="text-center" text="Our Partners" content="Why us? What do we offer? Introduction text placeholderWhy us? What do we offer? Introduction text placeholder" isMargin="mb-3"></SectionHeading>&ndash;&gt;-->
      <!--          <SectionHeading classname="text-center" text="OUR PARTNERS" isMargin="mb-3"></SectionHeading>-->

      <!--        </div>-->
      <!--        &lt;!&ndash;      OurPartners&ndash;&gt;-->
      <!--        <OurPartners></OurPartners>-->
      <!--      </section>-->

      <!-- top creators -->
      <TopCreators classname="section-space-t"></TopCreators>




      <section class="brand-section section-space">
        <div class="container">
          <div class="section-head text-center"><h2 class="mb-3">Backed by top firms &amp; industry leaders</h2>
            <p>Who are helping us pave the way towards a brand new digital economy</p></div>

          <div class="row g-gs">
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/111.png" alt=""
                                                             class="img-fluid"></div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/222.png" alt=""
                                                             class="img-fluid">
              </div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/333.png" alt=""
                                                             class="img-fluid">
              </div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/444.png" alt=""
                                                             class="img-fluid">
              </div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/555.png" alt=""
                                                             class="img-fluid">
              </div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="client-logo-item text-center"><img src="@/assets/images/partner/666.png" alt=""
                                                             class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-space">
        <div class="container">
          <SectionHeading classname="text-center" text="Support Payment Methods" isMargin="mb-3"></SectionHeading>
          <PayJoinSection></PayJoinSection>
        </div>
      </section>

      <!-- Blog  -->
<!--      <section class="blog-section">-->
<!--        <div class="container">-->
<!--          &lt;!&ndash; section heading &ndash;&gt;-->
<!--          <SectionHeading classname="text-center" :text="SectionData.blogData.title"-->
<!--                          :content="SectionData.blogData.content" isMargin="mb-3"></SectionHeading>-->
<!--          &lt;!&ndash; blog section &ndash;&gt;-->
<!--          <BlogSectionSlider></BlogSectionSlider>-->
<!--          &lt;!&ndash;          <div class="text-center mt-5">&ndash;&gt;-->
<!--          &lt;!&ndash;            <ButtonLink :text="SectionData.blogData.btnText" link="/blog"&ndash;&gt;-->
<!--          &lt;!&ndash;                        classname="btn-link btn-link-s1"></ButtonLink>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>&lt;!&ndash; .container &ndash;&gt;-->
<!--      </section>&lt;!&ndash; end blog-section &ndash;&gt;-->


    </div>
    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeroTwo from '@/components/n1/HeroTwo.vue'
import AboutUs from "@/components/n1/AboutUs";
import OurPartners from "@/components/n1/OurPartners";
import SeoSection from "@/components/n1/SeoSection";
import PayJoinSection from "@/components/n1/PayJoinSection"
import {useMeta} from 'vue-meta'
import homeApi from "@/apis/homeApi"
import HeroEight from "@/components/n1/HeroEight.vue"
import ProductsContainer from "@/components/n1/ProductsContainer"
import HeaderMain from "@/components/n1/HeaderMain"
import HowItWorkTwo from "@/components/n1/HowItWorkTwo"
import ProductsContainerSix from "@/components/n1/ProductsContainerSix"
import Footer from "@/pages/n1/Footer"
import SectionHeading from "@/components/n1/SectionHeading"
import BlogSectionSlider from "@/components/n1/BlogSectionSlider"
import TopCreators from "@/components/n1/TopCreators"

export default {
  name: 'Home',
  components: {
    HeroEight,
    HeroTwo, AboutUs, OurPartners, SeoSection, PayJoinSection,
    ProductsContainerSix,
    ProductsContainer,
    HeaderMain,
    HowItWorkTwo,
    Footer,
    SectionHeading,
    BlogSectionSlider,
    TopCreators
  },
  setup() {
    useMeta({
      title: 'Cloud Mining Services | Cloud Mining | GDMining',
      description: 'Discover high-quality cryptocurrency cloud mining services at GDMining, empowering your digital assets with secure and effective mining solutions.',
      link: [
        {rel: 'canonical', href: 'https://gdmining.com/'}
      ],
      htmlAttrs: {lang: 'en', amp: true}
    })
  },
  created() {
    homeApi.notification(res => {
      if (res.data.ifAlert === true) {
        this.promotionsShow = true
      }
      this.alert = res.data
    })
  },
  mounted() {
    setTimeout(() => {
      this.sharkFlag = true;
    }, 2000)
  },
  methods: {
    handleClick(event) {
      // if (event.target.id === 'promotions-popup') {
      //   this.promotionsShow = false;
      // }
      const rect = this.$refs.image.getBoundingClientRect();
      const x = event.clientX;
      const y = event.clientY;
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        this.promotionsShow = false;
        this.$nextTick(() => {
          const target = document.getElementById('contracts');
          target.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    },
  },
  data() {
    return {
      sharkFlag: false,
      promotionsShow: false,
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
      SectionData,
      productData: {
        title: 'CHOOSE MINING CONTRACTS',
        content: 'We offer a variety of cloud mining investment contracts, ensuring stable and reliable profits.',
        // contentTwo: "Explore on the world's best NFT marketplace with our beautiful NFT products. We want to be a part of your smile.",
        // btnText: 'View all auctions',
        // btnLink: '/explore',
        // btnLinkTwo: '/explore-v3',
        // btnLinkThree: '/explore-v4',
        // btnLinkFour: '/explore-v5',
        // btnLinkFive: '/explore-v6',
        // btnTextTwo: 'Load More',
        // meta: 'NTFs Products',
      }
    }
  }
}
</script>

<style>

.bg-cus {
  background-color: #ffe5d0;
}



.client-logo-item {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2.1rem;
  padding: 0;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 150px;
}

.client-logo-item img {
  max-width: 80%;
  height: auto;
}

.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}


#promotions-popup {
  .modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .my-shark {
  //animation:login-shake 0.8s 1 ease-in; animation: login-shake 0.8s 2 ease-in forwards;
  }

  @keyframes login-shake {
    0% {
      transform: scale(1);
    }
    10%, 20% {
      transform: scale(0.9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
      transform: scale(1.1) rotate(3deg);
    }
    40%, 60%, 80% {
      transform: scale(1.1) rotate(-3deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }
}


.testimonial {
  h2 {
    text-align: center;
  }

}

.swiper-button-prev,
.swiper-button-next {
  //display: none;
}

.section-space, .blog-section {
  padding: 50px 0;
}

.section-space-t, .section-space {
  padding-top: 20px;
}

@media (min-width: 576px) {
  .hero-slider-wrap {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

</style>
