<template>
  <section class="section-space how-it-work-section">
      <div class="container">
          <!-- section heading -->
          <SectionHeading classname="text-center" :text="titleThree"></SectionHeading>
          <!-- tab -->
          <Tab></Tab>
      </div><!-- end container -->
  </section><!-- end how-it-work-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionHeading from "@/components/n1/SectionHeading"
import Tab from "@/components/n1/Tab"

export default {
  name: 'HowItWorkTwo',
  components: {
    SectionHeading,
    Tab
  },
  data () {
    return {
      titleThree: 'Get Started in 3 simple steps',
    }
  }
}
</script>